import moment from "moment";
import Card from "components/card";
import Flex from "components/flex";
import Country from "components/icons/country";
import InfoOutline from "components/icons/info-outline";
import Location from "components/icons/location";
import Mail from "components/icons/mail";
import PhoneOn from "components/icons/phone-on";
import Neighbors from "components/icons/neighbors-icon";
import TelLinkReplacer from "components/tel-link-replacer";
import Text from "components/text";
import TextLinksReplacer from "components/text-links-replacer";
import React from "react";
import { Box } from "nhweb-shared/components/Box";

// TODO these texts could be moved to another folder in case we need translations
// or just to have all texts in one place
const WELCOME_MESSAGE =
  "Welcome! Your public safety profile is used to help the community stay informed about your Agency and access relevant information.";
const AGENCY_INFO_TITLE = "Account Information";

const formatText = text =>
  text ? (
    <Text color={"neutral300"} fontSize={1} lineHeight={"22px"}>
      {text}
    </Text>
  ) : null;

const formatPhone = (phone, extension) =>
  phone ? (
    <TelLinkReplacer
      color={"primary400"}
      fontSize={1}
      lineHeight={"22px"}
      phone={phone}
      extension={extension}
    />
  ) : null;

const formatLink = text =>
  text ? (
    <TextLinksReplacer
      color={"primary400"}
      fontSize={1}
      lineHeight={"22px"}
      overflowWrap={"anywhere"}
    >
      {text}
    </TextLinksReplacer>
  ) : null;

const formatAddress = (address1, address2, address3, city, state, zipCode) =>
  address1 || address2 || address3 || city || state || zipCode ? (
    <>
      {address1 && (
        <Text color={"neutral300"} fontSize={1} lineHeight={"22px"}>
          {address1}
        </Text>
      )}
      {address2 && (
        <Text color={"neutral300"} fontSize={1} lineHeight={"22px"}>
          {address2}
        </Text>
      )}
      {address3 && (
        <Text color={"neutral300"} fontSize={1} lineHeight={"22px"}>
          {address3}
        </Text>
      )}
      <Text color={"neutral300"} fontSize={1} lineHeight={"22px"}>
        {`${city || ""} ${state || ""} ${zipCode || ""}`}
      </Text>
    </>
  ) : null;

const formatWebsiteAndLinkedAccounts = (website, linkedAccounts) =>
  website || (linkedAccounts && Object.keys(linkedAccounts).length) ? (
    <>
      {website && (
        <>
          <Flex width={"100%"} marginBottom={"4px"}>
            <Flex flex={1}>
              <Text fontSize={2} fontWeight={2}>
                {"Website"}
              </Text>
            </Flex>
          </Flex>
          <TextLinksReplacer
            color={"primary400"}
            fontSize={1}
            lineHeight={"22px"}
            overflowWrap={"anywhere"}
          >
            {website}
          </TextLinksReplacer>
        </>
      )}
      {linkedAccounts && Object.keys(linkedAccounts).length ? (
        <>
          <Flex
            width={"100%"}
            marginBottom={"4px"}
            marginTop={website && linkedAccounts ? "20px" : "0"}
          >
            <Flex flex={1}>
              <Text fontSize={2} fontWeight={2}>
                {"Linked Accounts"}
              </Text>
            </Flex>
          </Flex>
          {Object.keys(linkedAccounts).map(provider =>
            linkedAccounts[provider]
              .map(account => (
                <TextLinksReplacer
                  key={account}
                  color={"primary400"}
                  fontSize={1}
                  lineHeight={"22px"}
                  overflowWrap={"anywhere"}
                >
                  {account}
                </TextLinksReplacer>
              ))
              .flat()
          )}
        </>
      ) : null}
    </>
  ) : null;

const AgencyDetails = ({
  agencyData,
  showWelcome,
  showAgencyHeader,
  quarterlySummary,
  quarterlySummaryPeriod
}) => {
  const {
    address1,
    address2,
    address3,
    city,
    description,
    email,
    extension,
    id,
    link,
    phoneNumber,
    state,
    website,
    zipCode,
    linkedAccounts
  } = agencyData;

  const startCurrentQuarterOfTheYearMoment = moment(
    quarterlySummaryPeriod.start_date
  )
    .utc()
    .startOf("quarter");
  const endCurrentQuarterOfTheYearMoment = moment(
    quarterlySummaryPeriod.end_date
  )
    .utc()
    .endOf("quarter");

  const startCurrentQuarterOfTheYear =
    startCurrentQuarterOfTheYearMoment.format("MMMM");
  const endCurrentQuarterOfTheYear =
    endCurrentQuarterOfTheYearMoment.format("MMMM");

  const currentYear = startCurrentQuarterOfTheYearMoment.format("YYYY");

  const cards = [
    {
      id: `${id}_description`,
      title: "About Us",
      icon: <InfoOutline />,
      content: formatText(description),
      showSeeMore: true
    },
    {
      id: `${id}_phone`,
      title: "Phone Number",
      icon: <PhoneOn />,
      content: formatPhone(phoneNumber, extension),
      showSeeMore: false
    },
    {
      id: `${id}_email`,
      title: "Email",
      icon: <Mail />,
      content: formatLink(email),
      showSeeMore: false
    },
    {
      id: `${id}_address`,
      title: "Address",
      icon: <Location />,
      content: formatAddress(
        address1,
        address2,
        address3,
        city,
        state,
        zipCode
      ),
      showSeeMore: false
    },
    {
      id: `${id}_website`,
      title: "",
      icon: <Country />,
      content: formatWebsiteAndLinkedAccounts(website, linkedAccounts),
      showSeeMore: false
    },
    {
      id: `${id}_link`,
      title: "Link",
      icon: <Country />,
      content: formatLink(link),
      showSeeMore: false
    }
  ];

  return (
    <Flex
      bg={{ sm: "white", md: "lightGrey" }}
      flexBasis={1}
      flexDirection={"column"}
      flexGrow={1}
      height={"100%"}
      overflow={"auto"}
      px={{ sm: 0, md: "40px" }}
      py={{ sm: 0, md: "24px" }}
      width={{ sm: 1, md: "auto" }}
    >
      {showWelcome && (
        <Text display={{ sm: "none", md: "block" }}>{WELCOME_MESSAGE}</Text>
      )}
      {showAgencyHeader && (
        <Flex
          bg={"transparent"}
          borderBottom={{ sm: 1, md: 0 }}
          pt={"24px"}
          px={{ sm: "24px", md: 0 }}
          width={1}
        >
          <Text color={"neutral300"} fontSize={1} fontWeight={2} mb={2}>
            {AGENCY_INFO_TITLE}
          </Text>
        </Flex>
      )}
      {cards.map(({ id, title, icon, content, showSeeMore }) =>
        content ? (
          <Card key={id} title={title} icon={icon} showSeeMore={showSeeMore}>
            {content}
          </Card>
        ) : null
      )}
      <QuarterlySummary
        startCurrentQuarterOfTheYear={startCurrentQuarterOfTheYear}
        endCurrentQuarterOfTheYear={endCurrentQuarterOfTheYear}
        currentYear={currentYear}
        quarterlySummary={quarterlySummary}
      />
    </Flex>
  );
};

const QuarterlySummaryPeriod = ({ startMonth, endMonth, year }) => (
  <Text color={"neutral300"} fontSize={"12px"} lineHeight={"22px"} mb={1}>
    {`${startMonth} - ${endMonth} ${year}`}
  </Text>
);

const QuarterlySummaryItem = ({ item }) => (
  <Box textTransform={"capitalize"} mb={1}>
    <Text color={"neutral300"} fontSize={"14px"} lineHeight={"22px"}>
      {`${item.name}: ${item.value}`}
    </Text>
  </Box>
);

const QuarterlySummary = ({
  startCurrentQuarterOfTheYear,
  endCurrentQuarterOfTheYear,
  currentYear,
  quarterlySummary
}) => {
  return (
    <Card title={"Quarterly Post Summary"} icon={<Neighbors />}>
      <QuarterlySummaryPeriod
        startMonth={startCurrentQuarterOfTheYear}
        endMonth={endCurrentQuarterOfTheYear}
        year={currentYear}
      />
      {Object.keys(quarterlySummary).map(categoryId => {
        return (
          <QuarterlySummaryItem
            key={categoryId}
            item={quarterlySummary[categoryId]}
          />
        );
      })}
    </Card>
  );
};

export default AgencyDetails;
