import React from "react";
import moment from "moment";

import Flex from "components/flex";
import Text from "components/text";
import PanelSection from "../panel-section";
import PublicAgency from "src/components/icons/public-agency";
import Neighbors from "components/icons/neighbors-icon";
import Download from "components/icons/download";

import AgencyType from "src/constants/agency-type";

const Panel = ({
  name,
  createdAt,
  showNavigation,
  agencyData,
  showUsageReportNavigation
}) => (
  <Flex
    borderRight={1}
    borderBottom={1}
    flexDirection={"column"}
    flexShrink={0}
    height={{ sm: "auto", md: "100%" }}
    px={{ sm: "24px", md: "40px" }}
    py={"24px"}
    width={{ sm: 1, md: "400px" }}
  >
    <Text fontSize={{ sm: 4, md: 5 }} fontWeight={2}>
      {name}
    </Text>
    {createdAt && (
      <Text fontSize={{ sm: 1, md: 2 }} mt={2}>
        {`Joined on ${moment(createdAt).format("MMMM YYYY")}`}
      </Text>
    )}
    {showNavigation ? (
      <>
        <PanelSection
          title={"Account"}
          icon={<PublicAgency />}
          to={`/agencies/${agencyData.uuid}`}
          content={"Information"}
          mb={showUsageReportNavigation ? 0 : 2}
        />
        {showUsageReportNavigation && (
          <PanelSection
            icon={<Download />}
            to={`/agencies/${agencyData.uuid}/reports`}
            content={"Usage Reports"}
            pt={1}
          />
        )}
        {agencyData.agencyType != AgencyType.NEWS_TEAM && (
          <PanelSection
            title={"Feed"}
            icon={<Neighbors />}
            to={`/agencies/${agencyData.uuid}/posts`}
            content={"Posts"}
          />
        )}
      </>
    ) : null}
  </Flex>
);

export default Panel;
