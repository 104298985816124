import React, { useEffect, useRef, useState } from "react";
import Flex from "components/flex";
import Location from "components/icons/location";
import Mail from "components/icons/mail";
import PhoneOn from "components/icons/phone-on";
import TelLinkReplacer from "components/tel-link-replacer";
import Text from "components/text";
import TextLinksReplacer from "components/text-links-replacer";
import LinkIcon from "components/icons/link";
import XLogo from "components/icons/twitter-logo";
import Link from "components/link";
import theme from "src/theme";
import AgencyProfileMap from "components/agency-profile-map";
import InfoOutline from "../icons/info-outline";

const formatPhone = (phone, extension) =>
  phone ? (
    <TelLinkReplacer
      color={"primary400"}
      fontSize={1}
      lineHeight={"24px"}
      phone={phone}
      extension={extension}
    />
  ) : null;

const formatLink = text =>
  text ? (
    <TextLinksReplacer
      color={"primary400"}
      fontSize={1}
      lineHeight={"24px"}
      overflowWrap={"anywhere"}
    >
      {text}
    </TextLinksReplacer>
  ) : null;

const formatAddress = (address1, address2, address3, city, state, zipCode) => {
  const stateAndZip = [state, zipCode].filter(Boolean).join(" ");
  const fullAddress = [address1, address2, address3, city, stateAndZip]
    .filter(Boolean)
    .join(", ");
  return fullAddress ? (
    <>
      {fullAddress && (
        <Text color={"neutral300"} fontSize={1} lineHeight={"24px"}>
          {fullAddress}
        </Text>
      )}
    </>
  ) : null;
};

const formatWebsiteAndLinkedAccounts = website =>
  website ? (
    <>
      <Flex width={"100%"} marginBottom={"4px"}>
        <Flex flex={1}>
          <Text fontSize={2} fontWeight={2}>
            {"Website"}
          </Text>
        </Flex>
      </Flex>
      <TextLinksReplacer
        color={"primary400"}
        fontSize={1}
        lineHeight={"24px"}
        overflowWrap={"anywhere"}
      >
        {website}
      </TextLinksReplacer>
    </>
  ) : null;

const formatText = text =>
  text ? (
    <Text color={"neutral300"} fontSize={1} lineHeight={"22px"}>
      {text}
    </Text>
  ) : null;

const DefaultInfoEntry = ({ title, icon, content }) => (
  <>
    <Flex height={"50px"} marginRight={"16px"}>
      {icon}
    </Flex>
    <Flex flexDirection={"column"}>
      <Text
        height={"24px"}
        fontSize={"16px"}
        fontWeight={"500"}
        lineHeight={"24px"}
      >
        {title}
      </Text>
      <Text
        height={"24px"}
        fontSize={"14px"}
        fontWeight={"400"}
        lineHeight={"24px"}
        marginTop={"2px"}
      >
        {content}
      </Text>
    </Flex>
  </>
);

const SocialAccountsEntry = ({ title, accounts }) => (
  <Flex width={"100%"} flexDirection={"column"}>
    <Flex width={"100%"} padding={"24px 0 8px 0"} borderBottom={theme.borders}>
      <Text lineHeight={"24px"} fontWeight={"500"} fontSize={"14px"}>
        {title}
      </Text>
    </Flex>
    <Flex flexDirection={"row"} paddingTop={"16px"}>
      {Object.keys(accounts).map(provider => {
        // Skip unsupported providers.
        if (provider !== "twitter") return null;

        const content = (
          <Flex flexDirection={"column"}>
            {accounts[provider].map(account => (
              <Link
                key={account}
                to={account}
                color={"primary400"}
                fontSize={"14px"}
                fontWeight={500}
                lineHeight={"24px"}
                overflowWrap={"anywhere"}
              >
                {account.replace("https://twitter.com/", "@")}
              </Link>
            ))}
          </Flex>
        );

        return DefaultInfoEntry({
          title: "X",
          icon: <XLogo />,
          content
        });
      })}
    </Flex>
  </Flex>
);

const InfoEntry = ({
  id,
  type,
  title,
  icon,
  content,
  accounts,
  lastItem,
  showSeeMore
}) => {
  const ref = useRef(null);
  const [showMore, setShowMore] = useState(!showSeeMore);
  const [hasTextOverflown, setHasTextOverflown] = useState(false);

  const toggleSetShowMore = () => setShowMore(!showMore);

  useEffect(() => {
    if (ref.current) {
      setHasTextOverflown(ref.current.offsetHeight < ref.current.scrollHeight);
    }
  }, []);

  return (
    <>
      <Flex
        flexDirection={"column"}
        borderBottom={!lastItem ? theme.borders : 0}
        padding={"16px 0"}
      >
        <Flex
          key={id}
          width={"100%"}
          flexDirection={"row"}
          maxHeight={showMore ? "none" : "74px"}
          overflow={"hidden"}
          ref={ref}
        >
          {type === "linked_accounts"
            ? SocialAccountsEntry({ title, accounts })
            : DefaultInfoEntry({ title, icon, content })}
        </Flex>
        {hasTextOverflown && showSeeMore && (
          <Text
            color={"primary500"}
            fontSize={1}
            fontWeight={2}
            mt={1}
            ml={"48px"}
            onClick={toggleSetShowMore}
            pointer
          >
            {showMore ? "See Less" : "See More"}
          </Text>
        )}
      </Flex>
    </>
  );
};

const AgencyInfo = ({ agencyData, isMobileScreen, config }) => {
  const {
    address1,
    address2,
    address3,
    city,
    email,
    extension,
    id,
    link,
    phoneNumber,
    state,
    website,
    zipCode,
    linkedAccounts,
    geomSimple,
    description
  } = agencyData;
  const cards = [
    {
      id: `${id}_description`,
      title: "About Us",
      icon: <InfoOutline height={"32px"} width={"32px"} />,
      content: formatText(description),
      showMore: true
    },
    {
      id: `${id}_phone`,
      title: "Phone Number",
      icon: <PhoneOn height={"32px"} width={"32px"} />,
      content: formatPhone(phoneNumber, extension),
      showMore: false
    },
    {
      id: `${id}_email`,
      title: "Email",
      icon: <Mail height={"32px"} width={"32px"} />,
      content: formatLink(email),
      showMore: false
    },
    {
      id: `${id}_address`,
      title: "Address",
      icon: <Location height={"32px"} width={"32px"} />,
      content: formatAddress(
        address1,
        address2,
        address3,
        city,
        state,
        zipCode
      ),
      showMore: false
    },
    {
      id: `${id}_website`,
      title: "",
      icon: <LinkIcon height={"32px"} width={"32px"} />,
      content: formatWebsiteAndLinkedAccounts(website),
      showMore: false
    },
    {
      id: `${id}_link`,
      title: "Link",
      icon: <LinkIcon height={"32px"} width={"32px"} />,
      content: formatLink(link),
      showMore: false
    },
    {
      id: `${id}_linked_accounts`,
      type: "linked_accounts",
      title: "Linked accounts",
      showMore: false,
      accounts: linkedAccounts
    }
    // Filter entries that have no content. This is required
    // to skip drawing bottom-border on the last item.
  ].filter(x => {
    // The API response currently returns an empty array of twitter accounts
    // if an account was added and then removed. To prevent rendering
    // an empty list of accounts, we perform this check.
    const hasAnyAccount =
      x.accounts && x.accounts["twitter"] && x.accounts["twitter"].length > 0;

    return x.content || hasAnyAccount;
  });

  return (
    <Flex
      backgroundColor={"white"}
      borderRadius={"8px"}
      aspectRatio={"16/9"}
      width={"100%"}
      border={theme.borders}
      flexDirection={"column"}
      alignItems={"center"}
      mb={3}
    >
      <Flex
        backgroundColor={"grey"}
        aspectRatio={"16/9"}
        width={"100%"}
        flexDirection={"column"}
        borderRadius={"8px 8px 0px 0px"}
      >
        <AgencyProfileMap
          polygon={geomSimple}
          isMobileScreen={isMobileScreen}
          config={config}
        />
      </Flex>
      <Flex
        backgroundColor={"white"}
        height={"auto"}
        width={"100%"}
        flexDirection={"column"}
        padding={"16px 24px"}
        borderRadius={"0 0 8px 8px"}
      >
        {cards.map(
          ({ id, type, title, icon, content, showMore, accounts }, index) => {
            const lastItem = cards.length - 1 === index;
            return InfoEntry({
              id,
              type,
              title,
              icon,
              content,
              accounts,
              lastItem,
              showSeeMore: showMore
            });
          }
        )}
      </Flex>
    </Flex>
  );
};

export default AgencyInfo;
