import React from "react";
import styled from "styled-components";
import { height, width } from "styled-system";

const StyledSvg = styled.svg`
  ${height}
  ${width}
`;

const VerifiedAccountProfile = ({
  fill = "none",
  height = "40",
  viewBox = "0 0 40 40",
  width = "40",
  ...props
}) => (
  <StyledSvg
    fill={fill}
    height={height}
    viewBox={viewBox}
    width={width}
    {...props}
  >
    <path
      d={
        "M31.0549 31.0691C33.8403 28.2425 35.5611 24.3571 35.5611 20.0685C35.5611 18.5023 35.3234 16.9898 34.8949 15.5614C34.3157 15.6824 33.7131 15.7429 33.0971 15.7429C31.8684 15.7429 30.6967 15.4942 29.632 15.0405C29.0997 14.8153 28.5942 14.5397 28.1221 14.217C25.7552 12.6138 24.1984 9.89812 24.1984 6.81945C24.1984 6.21447 24.262 5.62629 24.3759 5.05828C22.9296 4.63143 21.3996 4.39952 19.8127 4.41297C11.1451 4.48355 4.22836 11.5618 4.33215 20.2534C4.38236 24.4714 6.09648 28.2895 8.85178 31.0758C8.95221 31.1766 9.1196 31.0691 9.06939 30.9346C8.62412 29.7751 8.12194 28.1786 8.12194 26.8476C8.12194 24.4445 11.2489 23.6614 12.9328 23.3018C12.5679 26.404 13.0868 29.4558 14.3155 32.2219C14.3791 32.3631 14.5867 32.3026 14.5666 32.1513C14.1715 29.2877 14.0644 26.0813 14.4092 23.6984C14.8344 20.7609 18.0685 20.8281 19.883 20.8281C21.6976 20.8281 25.0588 20.7609 25.4874 23.6984C25.8322 26.0813 25.7251 29.2877 25.33 32.1513C25.3099 32.3026 25.5208 32.3631 25.5811 32.2219C26.8098 29.4558 27.332 26.404 26.9638 23.3018C28.6478 23.6581 31.7747 24.4445 31.7747 26.8476C31.7747 28.1719 31.2792 29.765 30.8339 30.9245C30.7837 31.059 30.9511 31.1665 31.0515 31.0657L31.0549 31.0691ZM12.0892 21.3726C10.5391 21.3726 9.28365 20.1122 9.28365 18.5594C9.28365 17.0066 10.5391 15.7463 12.0892 15.7463C13.6392 15.7463 14.898 17.0066 14.898 18.5594C14.898 20.1122 13.6426 21.3726 12.0892 21.3726ZM19.95 19.3896C18.0886 19.3896 16.582 17.8771 16.582 16.0118C16.582 14.1464 18.0886 12.634 19.95 12.634C21.8114 12.634 23.3179 14.1464 23.3179 16.0118C23.3179 17.8771 21.8114 19.3896 19.95 19.3896ZM27.8074 21.3726C26.2574 21.3726 24.9986 20.1122 24.9986 18.5594C24.9986 17.0066 26.254 15.7463 27.8074 15.7463C29.3609 15.7463 30.6163 17.0066 30.6163 18.5594C30.6163 20.1122 29.3609 21.3726 27.8074 21.3726Z"
      }
      fill={"#099AD6"}
    />
    <path
      d={
        "M37.5029 14.533C38.0519 16.2807 38.3499 18.1393 38.3499 20.0685C38.3499 30.2557 30.1108 38.5137 19.95 38.5137C9.78918 38.5137 1.55006 30.2557 1.55006 20.0685C1.55006 9.88133 9.78918 1.62335 19.95 1.62335C21.8449 1.62335 23.6728 1.91239 25.3936 2.44343C25.6615 1.97289 25.9661 1.5326 26.3109 1.1192C24.3123 0.443636 22.1797 0.0638428 19.9533 0.0638428C8.93213 0.0638428 0 9.01755 0 20.0652C0 31.1128 8.93213 40.0665 19.9533 40.0665C30.9745 40.0665 39.9067 31.1128 39.9067 20.0652C39.9067 17.7998 39.515 15.6354 38.8219 13.6053C38.4135 13.9515 37.9749 14.2607 37.5062 14.5296L37.5029 14.533Z"
      }
      fill={"#099AD6"}
    />
    <path
      d={
        "M32.8062 13.6457C36.5659 13.6457 39.6125 10.5905 39.6125 6.82283C39.6125 3.05515 36.5659 0 32.8062 0C29.0466 0 26 3.05515 26 6.82283C26 10.5905 29.0466 13.6457 32.8062 13.6457ZM28.9763 6.33885C29.3646 5.94897 29.9974 5.94897 30.3857 6.33885L31.6144 7.57234L35.2267 3.94918C35.6151 3.5593 36.2478 3.5593 36.6362 3.94918C37.0246 4.33905 37.0246 4.97428 36.6362 5.36416L31.6144 10.4023L28.9729 7.75383C28.5846 7.36395 28.5846 6.72872 28.9729 6.33885H28.9763Z"
      }
      fill={"#099AD6"}
    />
  </StyledSvg>
);

export default VerifiedAccountProfile;
