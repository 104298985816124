import React from "react";
import Box from "components/box";
import VerifiedAccountProfile from "src/components/icons/verified_account_profile";

const ProfileHeader = () => {
  return (
    <Box
      display={"block"}
      position={"relative"}
      width={"100%"}
      aspectRatio={{ sm: "16/9", lg: "3/1" }}
      margin={"0 auto"}
      marginLeft={"auto"}
      marginRight={"auto"}
      maxWidth={"1080px"}
    >
      <Box
        backgroundImage={"url('../../images/default_profile_cover.png')"}
        backgroundSize={"100% 100%"}
        display={"block"}
        position={"relative"}
        overflow={"hidden"}
        zIndex={1}
        width={"100%"}
        height={"100%"}
        borderRadius={{ md: 0, lg: "0 0 7px 7px" }}
      />
      <Box
        alignItems={"center"}
        display={"flex"}
        position={"relative"}
        border={"white solid 4px"}
        borderRadius={"100px"}
        justifyContent={"center"}
        width={{ sm: "80px", lg: "120px" }}
        height={{ sm: "80px", lg: "120px" }}
        margin={{ sm: "-44px 0 10px 24px", lg: "-92px 0 10px 32px" }}
        zIndex={2}
      >
        <Box
          backgroundColor={"unset"}
          height={{ sm: "64px", lg: "96px" }}
          width={{ sm: "64px", lg: "96px" }}
        >
          <VerifiedAccountProfile height={"100%"} width={"100%"} />
        </Box>
      </Box>
    </Box>
  );
};

export default ProfileHeader;
